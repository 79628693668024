/* ==========================================================================
   Gallery: Detail
   ========================================================================== */

@mixin fullscreen-content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.a17-gallery__detail {
  $gd: &;
  position: fixed;
  z-index: zi(detail);
  @include fullscreen-content;
  background-color: color(black);
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
  .a17--show-detail & {
    opacity: 1;
    visibility: visible;
  }
  .a17--hide-detail & {
    opacity: 0;
    visibility: hidden;
    transition: all 4s ease;
  }
  &__img {
    position: absolute;
    z-index: zi(detail-img);
    @include fullscreen-content;
    overflow: hidden;
    display: none;
    // live-version
    .live-version & {
      @include large {
        height: 75%;
      }
      @include medium {
        height: 67%;
      }
      @include small {
        height: 45%;
      }
    }
    .a17--show-img & {
      display: block;
    }
    &__el {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      .a17--zoom-in-img-detail & {
        transform: scale(1.2);
        transition: all 50s ease;
        // live-version
        .live-version & {
          transform: scale(1.05);
          @include large {
            transform: scale(1.025);
          }
          @include small {
            transform: scale(1.015);
          }
        }
      }
    }
  }
  &__video {
    position: absolute;
    z-index: zi(detail-video);
    @include fullscreen-content;
    video {
      position: absolute;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s ease;
      &.showed {
        opacity: 1;
        z-index: 3;
      }
      // live-version
      .live-version & {
        object-position: center center;
        @include small {
          object-position: 65% center;
        }
      }
    }
  }
  &__content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 8rem;
    align-items: center;
    z-index: zi(detail-content);
    @include fullscreen-content;
    text-align: center;
    color: color(white);
    .live-version & {
      padding: 4.8rem;
      @include large {
        height: auto;
        display: block;
        padding: 4rem 5.6rem;
        bottom: 0;
        top: auto;
      }
      @include medium {
        padding: 4rem 4rem 4.8rem;
      }
      @include small {
        top: 35%;
        bottom: auto;
        padding: 0 3.2rem 4rem;
      }
    }
    .a17--reverse-content & {
      flex-direction: row-reverse;
    }
  }
  &__item {
    flex-basis: 33.3333333%;
    // live-version
    .live-version & {
      flex-basis: 35%;
    }
    &:nth-child(2) {
      #{$gd}__desc {
        margin-top: 13.6rem;
        &::before {
          left: 2rem;
        }
        @include large {
          margin-top: 2rem;
        }
      }
    }
  }
  &__name {
    margin-bottom: 1.6rem;
    text-shadow: 0 4px 4px color(black);
    position: relative;
    display: inline-block;
    font-size: 0;
    line-height: 1;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 45%;
      left: -1.6rem;
      right: 3.2rem;
      bottom: 1rem;
      z-index: 1;
      background-color: color(black);
      .live-version & {
        left: -1.6rem;
        bottom: 0.4rem;
        top: 35%;
        right: 2.4rem;
      }
    }
    span {
      font-family: $font-secondary;
      font-size: 8.8rem;
      line-height: 1.4;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      z-index: 2;
      opacity: 0;
      transform: translateY(0.4rem);
      transition: all 2s ease;
      .live-version & {
        font-size: 6.4rem;
        @include large {
          font-size: 5.6rem;
        }
        @include medium {
          font-size: 4.8rem;
        }
      }
      .a17--show-name & {
        opacity: 1;
        transform: none;
      }
      @for $i from 1 through 20 {
        $td: ($i * 100) - 100;
        &:nth-child(#{$i}) {
          transition-delay: #{$td}ms;
        }
      }
      &:nth-child(6n + 2),
      &:nth-child(6n + 5) {
        font-family: $font-primary;
        font-weight: fw(bold);
        top: 4px;
        line-height: 1.5;
      }
    }
  }
  &__desc {
    font-size: 2.4rem;
    line-height: 1.5;
    margin-bottom: 0;
    text-shadow: 0 2px 2px color(black);
    position: relative;
    .live-version & {
      font-size: 1.8rem;
      line-height: 1.4;
      @include large {
        font-size: 2rem;
        height: auto !important;
      }
      @include medium {
        font-size: 1.8rem;
      }
      @include small {
        font-size: 2rem;
      }
    }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      background-color: color(black);
    }
    &::before {
      bottom: 50%;
      top: -1rem;
      left: -4rem;
      right: 9.6rem;
      .live-version & {
        top: -1.2rem;
        left: -3.2rem;
        right: 6.4rem;
      }
    }
    &::after {
      top: 45%;
      bottom: -2rem;
      left: -1.6rem;
      right: 2.4rem;
      .live-version & {
        left: -0.8rem;
        right: 1.6rem;
      }
    }
    span {
      position: relative;
      z-index: 2;
    }
  }
}
