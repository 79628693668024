/* ==========================================================================
   Components Gallery Remote
   ========================================================================== */

.a17-gallery-remote {
  &__toast {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    color: color(white);
    padding: 32px;
    text-align: center;
    background-color: rgba(#000, 0.5);
    margin-bottom: 0;
    @include font-size(h2);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    .show-toast & {
      opacity: 1;
      visibility: visible;
    }
  }
  &__head {
    padding: 16px 24px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: color(grey);
    box-shadow: 0 2px 16px rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease;
    .show-toast & {
      filter: blur(8px);
    }
  }
  &__brand {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    &__img {
      display: block;
      height: 48px;
    }
  }
  &__title {
    color: color(white);
    @include font-size(h3);
    margin-bottom: 0;
  }
  &__form {
    &__input {
      width: 100%;
      height: 48px;
      padding: 4px 16px;
      background-color: color(white);
      @include font-size(body);
      outline: 0;
      border: 0;
      border-radius: 8px;
    }
  }
  &__list {
    padding: 160px 24px 32px;
    transition: all 0.5s ease;
    .show-toast & {
      filter: blur(8px);
    }
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    &--hide {
      display: none;
    }
  }
  &__btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    z-index: 2;
    opacity: 0;
  }
  &__img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
  }
  &__name {
    color: color(white);
    margin-bottom: 0;
    margin-left: 16px;
  }
}
