/* ==========================================================================
   Variable: Colors
   ========================================================================== */

// colors
$colors: (
  primary: #004e92,
  secondary: #ffe1eb,
  tertiary: #ffb27d,
  black: #000,
  grey: #191a1a,
  white: #fff,
  overlay: rgba(#000, 0.7),
  shadow: rgba(#000, 0.1),
  shadow-hover: rgba(#000, 0.2),
  social-media: (
    facebook: #1877f2,
    twitter: #1da1f2,
    instagram: #405de6,
    whatsapp: #25d366,
    youtube: #ff0000,
    spotify: #1db954,
  ),
);
