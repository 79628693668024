/* ==========================================================================
   Variable: Typography
   ========================================================================== */

$font-primary: "Poppins", sans-serif;
$font-secondary: "DotGothic16", sans-serif;

// Font
$font: (
  family-primary: $font-primary,
  h1: (
    font-size: 4.8rem,
    line-height: 1.333,
  ),
  h2: (
    font-size: 3.2rem,
    line-height: 1.375,
  ),
  h3: (
    font-size: 2.4rem,
    line-height: 1.333,
  ),
  h4: (
    font-size: 2rem,
    line-height: 1.4,
  ),
  h5: (
    font-size: 1.8rem,
    line-height: 1.333,
  ),
  h6: (
    font-size: 1.6rem,
    line-height: 1.5,
  ),
  body: (
    font-size: 1.6rem,
    line-height: 1.5,
  ),
  label: (
    font-size: 1.4rem,
    line-height: 1.4,
  ),
  small: (
    font-size: 1.2rem,
    line-height: 1.3333,
  ),
  weight: (
    light: 300,
    regular: 400,
    bold: 800,
  ),
);
