/* ==========================================================================
   Components Pause
   ========================================================================== */

.a17-pause {
  position: fixed;
  z-index: zi(pause);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(color(black), 0.75);
  justify-content: center;
  text-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
  .a17--pause & {
    opacity: 1;
    visibility: visible;
  }
  &__content {
    position: relative;
    top: 6rem;
  }
  &__desc {
    display: none;
    color: color(white);
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 0;
    &--active {
      display: block;
      opacity: 0;
      transition: all 0.5s ease;
      transform: translateY(1.6rem);
      .a17--pause & {
        opacity: 1;
        transform: none;
        transition: all 1.2s ease 0s;
      }
    }
  }
}
