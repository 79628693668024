/* ==========================================================================
   Components Gate
   ========================================================================== */

.a17-gate {
  $g: &;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // --- video
  &__video {
    &__item {
      width: 73%;
      position: absolute;
      top: 0;
      left: 0;
      &:nth-child(2) {
        top: auto;
        left: auto;
        right: 0;
        bottom: 0;
        transform: rotate(180deg);
      }
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.4;
      object-position: center center;
      transition: all 1500ms ease 200ms;
      // -- show-greeting
      .a17--show-greeting & {
        opacity: 0;
        filter: blur(16px);
        transition: all 500ms ease 0ms;
      }
    }
  }
  // --- greeting-video
  &__greeting-video {
    &__item {
      width: 73%;
      height: 40rem;
      position: absolute;
      top: 0;
      left: 0;
      &:nth-child(1) {
        transform: rotate(90deg) translateY(-100%);
        transform-origin: left top;
      }
      &:nth-child(2) {
        left: auto;
        right: 0;
        transform: rotate(-90deg) translateY(-100%);
        transform-origin: right top;
      }
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left top;
      opacity: 0;
      filter: blur(16px);
      transition: all 500ms ease 0ms;
      // -- show-greeting
      .a17--show-greeting & {
        filter: none;
        opacity: 0.4;
        transition: all 1s ease 100ms;
      }
    }
  }
  // --- logo
  &__logo {
    position: absolute;
    z-index: zi(logo);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -170%);
    &__img {
      display: block;
      width: 20rem;
      height: auto;
      transition: all 1s ease 300ms;
      // -- hide (default before onload)
      .a17--hide & {
        opacity: 0;
        transform: scale(0.925);
      }
      // -- show
      .a17--show & {
        transition-delay: 0s;
      }
      // -- show-greeting
      .a17--show-greeting & {
        opacity: 0;
        filter: blur(8px);
        transform: translateY(-24px);
        transition: all 500ms ease 0s;
      }
    }
  }
  // --- caption
  &__caption {
    position: absolute;
    z-index: zi(caption);
    max-width: 1184px;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 10%);
    transition: all 500ms ease 400ms;
    // -- hide
    .a17--hide & {
      opacity: 0;
      visibility: hidden;
      filter: blur(4px);
      transform: translate(-50%, 20%);
    }
    // -- show-greeting
    .a17--show-greeting & {
      opacity: 0;
      visibility: hidden;
      filter: blur(4px);
      transform: translate(-50%, 20%);
      transition-delay: 0s;
    }
  }
  &__desc {
    font-size: 2.8rem;
    line-height: 1.5;
    margin-bottom: 0;
  }

  // --- greeting
  &__greeting {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 50%;
    left: 8rem;
    right: 8rem;
    z-index: zi(greeting);
    transform: translateY(-40%);
    &__label {
      font-family: $font-secondary;
      font-size: 3.2rem;
      line-height: 1.45;
      text-transform: uppercase;
      margin-bottom: 16px;
      span {
        &:nth-child(2),
        &:nth-child(5) {
          font-family: $font-primary;
          font-weight: fw(bold);
        }
      }
    }
    &__name {
      font-weight: fw(regular);
      font-size: 4rem;
      line-height: 1.45;
      margin-bottom: 4px;
    }
    &__occupation {
      font-size: 2.4rem;
      line-height: 1.45;
      font-weight: fw(light);
      margin-bottom: 0;
    }
    &__text {
      flex: 1;
      padding-right: 3.2rem;
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transform: translateY(-16px);
      transition: all 500ms ease 0s;
      // -- show-greeting
      .a17--show-greeting & {
        visibility: visible;
        opacity: 1;
        filter: none;
        transform: none;
        transition-delay: 200ms;
      }
    }
    &__logo {
      padding-top: 1rem;
      filter: blur(8px);
      opacity: 0;
      visibility: hidden;
      transform: translateY(16px);
      transition: all 500ms ease 0s;
      // -- show-greeting
      .a17--show-greeting & {
        visibility: visible;
        opacity: 1;
        filter: none;
        transform: none;
        transition-delay: 200ms;
      }
      &__img {
        display: block;
        width: 22.5rem;
        height: auto;
      }
    }
  }

  // --- attendance
  &__attendance {
    position: absolute;
    width: 100%;
    z-index: zi(attendance);
    bottom: 64px;
    padding-left: 120px;
    padding-right: 120px;
    text-align: center;
    &__title {
      font-size: 1.6rem;
      line-height: 1.5;
      font-weight: fw(regular);
      text-transform: uppercase;
      margin-bottom: 1.6rem;
      transition: all 500ms ease 500ms;
      opacity: 0;
      visibility: hidden;
      filter: blur(4px);
      transform: translateY(-8px);
      // -- show-attendance
      .a17--show-attendance & {
        opacity: 1;
        filter: none;
        visibility: visible;
        transform: none;
      }
      // -- show-greeting
      .a17--show-greeting & {
        transition-delay: 0s;
      }
    }
    &__list {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.6rem;
      line-height: 1.5;
      margin-bottom: 0;
      transition: all 500ms ease 600ms;
      opacity: 0;
      visibility: hidden;
      filter: blur(4px);
      transform: translateY(8px);
      // -- show-attendance
      .a17--show-attendance & {
        opacity: 1;
        filter: none;
        visibility: visible;
        transform: none;
      }
      // -- show-greeting
      .a17--show-greeting & {
        transition-delay: 0s;
      }
    }
  }
  &__invitation {
    opacity: 0;
    &__item {
      border: 0;
      outline: 0;
      opacity: 0;
      padding: 0;
      font-size: 0;
      background-color: transparent;
    }
  }
}
