.a17-stage-remote {
  &__head {
    padding: 16px 24px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: color(grey);
    box-shadow: 0 2px 16px rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease;
    .show-toast & {
      filter: blur(8px);
    }
  }
  &__brand {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    &__img {
      display: block;
      height: 48px;
    }
  }
  &__title {
    color: color(white);
    @include font-size(h3);
    margin-bottom: 0;
  }
  &__list {
    padding: 120px 24px 32px;
    transition: all 0.5s ease;
    .show-toast & {
      filter: blur(8px);
    }
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    border: 1px solid #aaa;
    padding: 20px;
    border-radius: 10px;

    &:last-child {
      margin-bottom: 0;
    }
    &--hide {
      display: none;
    }

    &:hover {
      background-color: #222;
    }
  }
  &__btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    z-index: 2;
    opacity: 0;
  }
  &__name {
    color: color(white);
    margin-bottom: 0;
    margin-left: 16px;
  }

  &__separator {
    border-bottom: 2px solid #555;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin: 50px 0px;
  }
}