/* ==========================================================================
   Components Stage
   ========================================================================== */

.a17-stage {
  min-width: 800px;
  min-height: 800px;
  height: 100%;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  overflow: hidden;

  &--fade-out {
    transition-delay: 2000ms;
    animation-timing-function: ease;
    animation-duration: 2000ms;
    animation-name: fadeOut;
  }

  &--fade-in {
    transition-delay: 1000ms;
    animation-timing-function: ease;
    animation-duration: 1000ms;
    animation-name: fadeIn;
  }

  &__bg {
    width: 130%;
    height: 130%;
    background-image: url("../img/resources/bg-profile.jpg");
    background-size: 105%;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0px;
    left: -20px;
    opacity: 1;

    &-first-animation {
      transition-delay: 2500ms;
      animation-timing-function: ease;
      animation-duration: 2500ms;
      animation-name: profileBgAnimation;
    }

    &-loop-animation {
      transition-delay: 10000ms;
      animation-timing-function: ease;
      animation-duration: 10000ms;
      animation-iteration-count: infinite;
      animation-name: profileBgLoopAnimation;
    }
  }

  &__bg-effect,
  &__digital-transformation__bg-effect {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;

    video {
      height: 100%;
      width: auto;
      position: absolute;
      left: 0px;
      top: 0px;
      display: none;
    }
  }

  &__avatar {
    width: 85%;
    height: 88%;
    position: absolute;
    bottom: 0px;
    right: -7%;
    opacity: 1;
    object-fit: contain;

    &--animate {
      transition-delay: 2500ms;
      animation-timing-function: ease;
      animation-duration: 2500ms;
      animation-name: avatarAnimation;
    }
  }

  &__info {
    position: absolute;
    top: 42%;
    left: 0;
    padding-left: 5%;
    margin: auto;
    width: 100%;
    z-index: 1;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-digi {
      align-items: center;
      padding-left: 0;
    }

    &--animate {
      transition-delay: 2500ms;
      animation-timing-function: ease;
      animation-duration: 2500ms;
      animation-name: infoAnimation;
    }

    &-name,
    &-digi-name {
      color: white;
      font-size: 3.8rem;
      text-align: left;
      font-weight: 600;
    }

    &-digi-name {
      font-size: 4.8rem;
      text-align: center;
    }

    &-role,
    &-digi-role {
      color: white;
      font-weight: 400;
      font-size: 2rem;
      text-align: left;
      max-width: 55%;
    }

    &-digi-role {
      font-size: 2.6rem;
      text-align: center;
      max-width: 70%;
    }
  }

  &__text-shade {
    width: 100%;
    height: 100%;
    background-image: url("../img/resources/shade.png");
    background-repeat: repeat-x;
    background-position: center bottom;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
  }

  &__greetings {
    width: 100%;
    height: 100%;
    position: absolute;
    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      video {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center center;
      }
    }

    .a17-gallery__logo {
      top: 43%;
      &__img {
        transition: all 1s ease 100ms;
        width: 16rem;
        // -- show-greeting
        .a17--show-greeting-stage & {
          opacity: 0;
          filter: blur(8px);
          transform: translateY(-24px);
          transition: all 500ms ease 0s;
        }
      }
    }

    &__greet-text {
      color: white;
      width: 71%;
      height: 10%;
      text-align: center;
      top: 50%;
      position: absolute;
      z-index: 3;
      left: 0px;
      right: 0px;
      margin: auto;
      font-size: 2.1rem;
      transition: all 500ms ease 200ms;
      // -- show-greeting
      .a17--show-greeting-stage & {
        opacity: 0;
        visibility: hidden;
        filter: blur(4px);
        transform: translateY(16px);
        transition-delay: 0s;
      }
    }

    &__attendance {
      position: absolute;
      z-index: 3;
      bottom: 0;
      padding: 0 5rem 5rem;
      text-align: center;
      width: 100%;
      color: color(white);
    }

    &__attendance-title {
      font-size: 1.3rem;
      margin-bottom: 1.8rem;
      transition: all 500ms ease 0s;
      opacity: 0;
      visibility: hidden;
      filter: blur(4px);
      transform: translateY(-8px);
      // -- show-attendance-gate
      .a17--show-attendance-gate & {
        opacity: 1;
        filter: none;
        visibility: visible;
        transform: none;
        transition-delay: 200ms;
      }
    }

    &__attendance-list {
      text-align: center;
      font-size: 1.2rem;
      transition: all 500ms ease 0s;
      opacity: 0;
      visibility: hidden;
      filter: blur(4px);
      transform: translateY(8px);
      // -- show-attendance-gate
      .a17--show-attendance-gate & {
        opacity: 1;
        filter: none;
        visibility: visible;
        transform: none;
        transition-delay: 250ms;
      }
    }
    &__detect {
      position: absolute;
      top: 50%;
      z-index: 3;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      left: 0;
      width: 100%;
      padding: 0 5.6rem;
      transform: translateY(-50%);
    }
    &__text {
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transform: translateY(-16px);
      transition: all 500ms ease 0s;
      // -- show-greeting-stage
      .a17--show-greeting-stage & {
        visibility: visible;
        opacity: 1;
        filter: none;
        transform: none;
        transition-delay: 200ms;
      }
    }
    &__logo {
      padding-top: 1rem;
      filter: blur(8px);
      opacity: 0;
      visibility: hidden;
      transform: translateY(16px);
      transition: all 500ms ease 0s;
      // -- show-greeting-stage
      .a17--show-greeting-stage & {
        visibility: visible;
        opacity: 1;
        filter: none;
        transform: none;
        transition-delay: 200ms;
      }
      &__img {
        display: block;
        width: 14rem;
      }
    }
    &__welcome {
      span {
        font-family: $font-secondary;
        font-size: 2.4rem;
        text-transform: uppercase;
        &:nth-child(2),
        &:nth-child(5) {
          font-family: $font-primary;
          font-weight: fw(bold);
        }
      }
    }
    &__name {
      color: color(white);
      font-size: 2.5rem;
      margin-bottom: 0.5%;
    }

    &__role {
      color: color(white);
      font-weight: fw(light);
      font-size: 1.7rem;
    }
  }

  &__digital-transformation {
    .a17-stage__info {
      position: absolute;
      height: 15%;
      top: 38%;
      bottom: auto;
      margin: auto;
      transition-delay: 5000ms;
      animation-timing-function: ease;
      animation-duration: 5000ms;
      animation-name: fadeIn;
    }

    .a17-stage__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      left: 0px;
      top: 0px;
      z-index: 0;
    }

    .a17-stage__info-digi-logo {
      position: absolute;
      bottom: -60%;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
    }

    .a17-stage__info-logo {
      width: 12%;
      margin: 0px 2%;
      object-fit: contain;
      transition-delay: 2000ms;
      animation-timing-function: ease-in-out;
      animation-duration: 2000ms;
      animation-name: fadeInZoom;

      &.logo-telkomsel {
        margin-top: 1%;
      }

      &.logo-astra {
        width: 15%;
        margin-top: 1%;
      }

      &.logo-prodia {
        width: 9%;
      }
    }
  }

  &__ace17 {
    .a17-stage__ace17-vid video {
      width: 100%;
      height: 100%;
    }
  }

  &__confetti1,
  &__confetti2 {
    .a17-stage__confetti-vid video {
      width: 100%;
      height: 100%;
    }
  }

  &__music1,
  &__music2,
  &__music3,
  &__music4,
  &__music5,
  &__party {
    .a17-stage__music-vid video {
      width: 100%;
      height: 100%;
    }
  }

  &__screen {
    display: none;
  }

  &__btn-trigger {
    position: absolute;
    left: 0px;
    top: 20px;
    z-index: 999;
    width: 64px;
    height: 64px;
  }
  &__invitation {
    opacity: 0;
    &__item {
      border: 0;
      outline: 0;
      opacity: 0;
      padding: 0;
      font-size: 0;
      background-color: transparent;
    }
  }
}

@keyframes profileBgAnimation {
  0% {
    background-size: 120%;
    top: -20px;
    left: 0px;
    opacity: 0;
  }
  100% {
    background-size: 105%;
    top: 0px;
    left: -20px;
    opacity: 1;
  }
}

@keyframes profileBgLoopAnimation {
  0% {
    top: 0px;
    left: -20px;
  }
  25% {
    top: 0px;
    left: 0px;
  }
  50% {
    top: -20px;
    left: 0px;
  }
  90% {
    top: -20px;
    left: -20px;
  }
  100% {
    top: 0px;
    left: -20px;
  }
}

@keyframes avatarAnimation {
  0% {
    bottom: -100px;
    // left: -30px;
    right: 0%;
    opacity: 0.5;
  }
  100% {
    bottom: 0px;
    // left: 0px;
    right: -7%;
    opacity: 1;
  }
}

@keyframes infoAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
