/* ==========================================================================
   Gallery: Main
   ========================================================================== */

.a17-gallery {
  $g: &;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  &--detail {
    opacity: 0;
    visibility: hidden;
  }
  // --- play
  .a17--play & {
    opacity: 0;
    transition: all 1s ease;
  }
  // --- restart
  .a17--restart & {
    opacity: 1;
  }
  // --- logo
  &__logo {
    position: absolute;
    z-index: zi(logo);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s ease 0s;
    // --- play
    .a17--play & {
      filter: blur(8px);
      transform: scale(0.95) translate(-50%, -50%);
      transition: all 1s ease 0s;
    }
    // --- pause
    .a17--pause & {
      transform: translate(-50%, -200%);
      transition: all 0.75s ease 0s;
    }
    &__img {
      display: block;
      width: 20rem;
      height: auto;
      transition: all 2.5s ease 500ms;
      // live-version
      .live-version & {
        width: 16rem;
      }
      // -- hide (default before onload)
      .a17--hide & {
        opacity: 0;
        transform: scale(0.925);
      }
      // -- show
      .a17--show & {
        opacity: 1;
        transform: none;
        transition-delay: 0s;
      }
      // -- restart
      .a17--restart & {
        opacity: 0;
        transform: scale(0.925);
        transition-delay: 0ms;
      }
    }
  }
  // --- decoration
  &__decoration {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 1s ease 500ms;
    // -- hide (default before onload)
    .a17--hide & {
      opacity: 0;
    }
    // --- play
    .a17--play & {
      transform: scale(1.1);
      filter: blur(8px);
      transition: transform 1s ease 0ms;
    }
    // --- pause
    .a17--pause & {
      filter: blur(8px);
      transition: all 1s ease 0s;
    }
    // -- restart
    .a17--restart & {
      opacity: 0;
      transition-delay: 0ms;
    }
    &__item {
      width: 54%;
      height: 100%;
      position: absolute;
      top: 0;
      // live-version
      .live-version & {
        width: 52%;
      }
      &:nth-child(1) {
        left: 0;
        transform: translateX(-20%);
      }
      &:nth-child(2) {
        right: 0;
        transform: translateX(20%) rotate(180deg);
      }
    }
    video {
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      object-fit: cover;
      // live-version
      .live-version & {
        object-position: right center;
      }
    }
  }
  // --- list
  &__list {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  &__placeholder {
    opacity: 0;
  }
  &__item {
    width: 4em;
    height: 4em;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transform: translateX(-8px) scale(1.15);
    transform-origin: center center;
    transition: all 1.75s ease;
    // live-version
    .live-version & {
      width: 3.6em;
      height: 3.6em;
    }
    &:nth-child(31n) {
      .a17--show & {
        opacity: 0;
      }
    }
    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      z-index: 3;
      opacity: 0;
      padding: 0;
      font-size: 0;
      background-color: transparent;
    }

    // --- show
    .a17--show & {
      opacity: 1;
    }

    // -- z-index
    $duration-increment: 1;
    @for $i from 1 through 30 {
      $zi: 50 - $i;
      $time-duration: $duration-increment * 500 + 12000;
      @if ($i%4 == 0) {
        $duration-increment: 1;
      }

      $pattern: 1;
      @if ($i%2 == 0) {
        $pattern: 3;
      } @else if ($i%3 == 0) {
        $pattern: 2;
      }

      &:nth-child(#{$i}) {
        z-index: #{$zi};

        @if ($i%2 == 0) {
          transform: translateX(8px) scale(1.15);
        }

        #{$g}__box {
          &::before {
            background-image: url('../img/pattern/gallery-'+#{$pattern}+'.png');
          }
        }

        // --- show
        .a17--show & {
          $animation-name: leftToRight;
          $td: ($i * 175) - 175;

          transform: translateX(-8px);
          transition-delay: #{$td}ms;
          animation-timing-function: ease;
          animation-iteration-count: infinite;

          @if ($i%2 == 0) {
            transform: translateX(8px);
            $animation-name: rightToLefft;
          }

          $ad: $td + 2500;
          animation-duration: #{$time-duration}ms;
          animation-name: #{$animation-name};
          animation-delay: #{$ad}ms;
        }
      }
      $duration-increment: $duration-increment + 1;
    }

    // -- size
    // -- S
    font-size: $sizeS;
    // live-version
    .live-version & {
      font-size: $sizeSlive;
    }
    // -- M
    @each $vM in $listM {
      &:nth-child(#{$vM}) {
        font-size: $sizeM;
        .live-version & {
          font-size: $sizeMlive;
        }
      }
    }
    // -- L
    @each $vL in $listL {
      &:nth-child(#{$vL}) {
        font-size: $sizeL;
        .live-version & {
          font-size: $sizeLlive;
        }
      }
    }

    // -- position
    @each $child, $properties in $position {
      &:nth-child(#{$child}) {
        @each $property, $value in $properties {
          #{$property}: #{$value};
        }
      }
    }
  }
  &__box {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    // --- hover
    &:hover {
      transform: scale(1.05);
      // --- play
      .a17--play & {
        transform: none;
      }
    }
    // --- play
    .a17--play & {
      transform: scale(0.95);
      filter: blur(8px);
      transition: all 1s ease 0s;
    }
    // --- pause
    .a17--pause & {
      filter: blur(8px);
      transition: all 1s ease 0s;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    object-fit: cover;
    object-position: center center;
  }
}
