/* ==========================================================================
   Components Scan
   ========================================================================== */

.a17-scan {
  $s: &;
  width: 100%;
  height: 100%;
  padding: 32px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__logo {
    position: fixed;
    top: 32px;
    left: 32px;
    &__img {
      display: block;
      height: 64px;
    }
  }
  &__img {
    width: 240px;
    height: 240px;
    margin: 32px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 4px #be0d0d;
    box-shadow: 0 0 48px 8px rgba(#be0d0d, 0.9);
    transition: all 1s ease;
    .a17--nfc-on & {
      border-color: #0c9713;
      box-shadow: 0 0 48px 8px rgba(#0c9713, 0.9);
    }
    &__el {
      display: block;
      height: 80px;
    }
  }
  &__title {
    font-size: 4rem;
    font-weight: fw(regular);
    margin-bottom: 32px;
  }
  &__btn {
    border: 0;
    outline: 0;
    background-color: transparent;
    padding: 1.2rem 2.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: color(white);
    border: solid 2px color(white);
    background-color: rgba(color(white), 0.1);
    border-radius: 48px;
  }
}
