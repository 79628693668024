/* ==========================================================================
   Gallery: Size
   ========================================================================== */

// -- size S
$sizeS: 1.8rem;
$sizeSlive: 1.6rem;

// -- size M
$sizeM: 2.6rem;
$sizeMlive: 2.4rem;
$listM: 2, 5, 8, 11, 17, 18, 22, 23, 24, 26;

// -- size L
$sizeL: 3.8rem;
$sizeLlive: 3.2rem;
$listL: 1, 3, 4, 9, 14, 16, 19, 25, 26;
