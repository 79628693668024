/* ==========================================================================
   Reboot
   ========================================================================== */

/*/
 * Base
 * -----------------------------------------------------------------------------
/*/

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-text-stroke-width: 0;
  -webkit-text-stroke-color: transparent;
}

html {
  min-height: 100%;
  font-size: 62.5%; /* 1rem == 10px */
  position: relative;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

@-ms-viewport {
  width: device-width;
}

[hidden] {
  display: none !important;
}

a {
  font-weight: inherit;
  outline: 0;
  color: inherit;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

a img {
  border: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
  font-size: 1.2rem;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

hr {
  box-sizing: content-box;
  overflow: visible;
}

// /*/
//  * Content Division
//  * -----------------------------------------------------------------------------
// /*/

header,
section,
footer,
aside,
nav,
main,
article,
figure {
  display: block;
}

// /*/
//  * Typography
//  * -----------------------------------------------------------------------------
// /*/

strong,
b {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

u {
  text-decoration: underline;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 1;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

p,
ul,
ol,
table,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}

ul,
ol {
  padding-left: 24px;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

/*/
 * Form
 * -----------------------------------------------------------------------------
/*/

label,
button {
  -webkit-tap-highlight-color: rgba(#000, 0);
  user-select: none;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  outline: 0;
}

button,
select {
  text-transform: none;
}

select,
option {
  outline: 0;
  &:active,
  &:hover {
    outline: 0;
  }
}

button {
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration,
[type="search"] {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*/
 * Table
 * -----------------------------------------------------------------------------
/*/

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}
