/* ==========================================================================
   Gallery: Animation
   ========================================================================== */

@keyframes leftToRight {
  from {
    transform: translateX(-8px);
  }
  50% {
    transform: translateX(8px) rotateY(5deg);
  }
  to {
    transform: translateX(-8px);
  }
}

@keyframes rightToLefft {
  from {
    transform: translateX(8px);
  }
  50% {
    transform: translateX(-8px) rotateY(5deg);
  }
  to {
    transform: translateX(8px);
  }
}
