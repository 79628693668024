/* ==========================================================================
   Variable: z-index
   ========================================================================== */

$z-index: (
  brand: 59,
  logo: 57,
  pause: 56,
  loading: 57,
  detail: 58,
  detail-img: 1,
  detail-video: 3,
  detail-content: 4,
  caption: 8,
  attendance: 8,
  greeting: 8,
);
