/* ==========================================================================
   Components Brand
   ========================================================================== */

.a17-brand {
  $b: &;
  position: fixed;
  z-index: zi(brand);
  top: 6.4rem;
  left: 6.4rem;
  display: flex;
  border-radius: 1.4rem;
  overflow: hidden;
  padding: 1.6rem;
  // live-version
  .live-version & {
    top: 4rem;
    left: 4rem;
    padding: 2rem;
    @include large {
      top: 3.2rem;
      left: 3.2rem;
    }
    @include medium {
      padding: 1.6rem;
      top: 2.8rem;
      left: 2.8rem;
    }
    @include small {
      padding: 2rem;
    }
  }
  &--stage {
    top: 5.6rem;
    left: 5.6rem;
  }
  &--gate {
    top: 7.2rem;
    left: 8rem;
    #{$b} {
      &::before {
        transition-delay: 200ms;
      }
      &__img {
        transition-delay: 100ms;
      }
    }
  }
  // --- pause
  .a17--pause & {
    filter: blur(8px);
    transition: all 1s ease 0s;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: color(grey);
    transition: opacity 2.5s ease 2s;
    // -- hide (default before onload)
    .a17--hide & {
      opacity: 0;
    }
  }

  &__img {
    position: relative;
    z-index: 2;
    display: block;
    width: 4rem;
    transition: all 2.5s ease 1s;
    // -- hide (default before onload)
    .a17--hide & {
      opacity: 0;
      transform: scale(0.95);
    }
    // live-version
    .live-version & {
      width: 4.4rem;
      @include large {
        width: 4rem;
      }
      @include medium {
        width: 3.6rem;
      }
      @include small {
        width: 4rem;
      }
    }
  }
}
