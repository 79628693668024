/* ==========================================================================
   Gallery: Position
   ========================================================================== */

// -- position 2 - 10
$position2until10: (
  1: (
    right: "38%",
    bottom: "16%",
  ),
  2: (
    left: "40%",
    top: "20%",
  ),
  3: (
    left: "30.5%",
    top: "8%",
  ),
  4: (
    right: "28%",
    bottom: "10%",
  ),
  5: (
    left: "31%",
    bottom: "21%",
  ),
  6: (
    right: "42%",
    top: "15%",
  ),
  7: (
    left: "46%",
    bottom: "20%",
  ),
  8: (
    left: "28%",
    top: "40%",
  ),
  9: (
    right: "28%",
    top: "11%",
  ),
  10: (
    left: "48%",
    top: "6%",
  ),
);

// -- position 11 - 20
$position11until20: (
  11: (
    right: "26%",
    top: "42%",
  ),
  12: (
    left: "25%",
    top: "24%",
  ),
  13: (
    right: "23%",
    bottom: "25%",
  ),
  14: (
    left: "21%",
    bottom: "8%",
  ),
  15: (
    right: "22%",
    top: "8%",
  ),
  16: (
    right: "16%",
    top: "25%",
  ),
  17: (
    left: "20%",
    top: "44%",
  ),
  18: (
    right: "16%",
    bottom: "12%",
  ),
  19: (
    left: "15%",
    top: "13%",
  ),
  20: (
    right: "18%",
    bottom: "33%",
  ),
);

// -- position 21 - 30
$position21until30: (
  21: (
    left: "15%",
    bottom: "28%",
  ),
  22: (
    left: "14%",
    bottom: "4%",
  ),
  23: (
    right: "13%",
    top: "5%",
  ),
  24: (
    left: "8%",
    top: "35%",
  ),
  25: (
    right: "6%",
    bottom: "27%",
  ),
  26: (
    left: "5%",
    bottom: "13%",
  ),
  27: (
    right: "10%",
    top: "28%",
  ),
  28: (
    left: "3%",
    top: "44%",
  ),
  29: (
    right: "10%",
    bottom: "8%",
  ),
  30: (
    right: "5%",
    top: "12%",
  ),
);

$positionTemp: map-merge($position2until10, $position11until20);
$position: map-merge($positionTemp, $position21until30);
